import React, { useState,useEffect, useCallback, RefObject, useRef, createRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Input from 'components/Input';
import ArrowButton from 'components/Button/ArrowButton';
import { Wrapper, HalfBox, Box, BoxSelect, ErrorMessage } from './form-styles';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from "react-helmet";

const ERROR_MESSAGE = 'Server Error, message cannot be send. Please contact us by phone.';

const ButtonContainer = styled.div`

    padding-left: 10px;
    padding-right: 10px;
    width: 100%;

    button {
        width: 100%;
        text-align: center;
        display: block;
        font-size: 18px;
        height: 55px;
        border-radius: 10px;
        margin-top: 8px !important;
    }

    svg {
      display: none;
    }

    .en .zh {
      display:none;
    }

    .en .kr {
      display:none;
    }

    .en .ja {
      display:none;
    }

    .zh .en {
      display:none;
    }

    .zh .kr {
      display:none;
    }

    .zh .ja {
      display:none;
    }

    .kr .en {
        display:none;
    }

    .kr .zh {
        display:none;
    }

    .kr .ja {
        display:none;
    }

    .ja .en {
        display:none;
    }

    .ja .zh {
        display:none;
    }

    .ja .kr {
        display:none;
    }
`;

const Select = styled.select`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid rgb(0,175,133);
  text-transform: capitalize;
  padding: 6px 15px;
  border-radius: 10px;
  height: 49px;
  width: 100%;
  margin-bottom: 10px;

  &::placeholder {
    opacity: 0.3;
  }

  &:focus {
    opacity: 1;
  }
`;

interface FormData {
  company_name: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface OwnProps {
  innerRef: RefObject<any>;
  rows: RefObject<any>;
  cols: RefObject<any>;
  textareaValue: RefObject<any>;
  limit: RefObject<any>;
}

const ContactFormChina = ({ innerRef, rows, cols, textareaValue, limit }: OwnProps) => {
  const intl = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            functionEndpoint
          }
        }
      }
    `,
  );
  const { register, handleSubmit, errors } = useForm<FormData>();
  const getErrors = (name: keyof FormData) => errors[name] && errors[name]?.message;
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isCountryOther, setIsCountryOther] = useState("");
  const [value, setValue] = useState();
  const [currentCountryCode, setCurrentCountryCode] = useState(null);
  const [messageVal, setMessageVal] = useState("");
  const [charCount, setCharCount] = useState(0);
  const TEST_SITE_KEY = "6LcXb2khAAAAAKX6vGijKqjBsaiAkE_dOWYZMxlQ";
  const [CaptchaValue, setCaptchaValue] = useState("[empty]");
  const [callback, setCallBack] = useState("not fired");
  const [expired, setExpired] = useState(false);
  const _reCaptchaRef = createRef();
  const site_key = "7fe715a1-151f-4c63-b497-bd971974df05";

  const handleCaptchaChange = value => {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
    // if value is null recaptcha expired
    if (value === null) setExpired(true);
  };

  const asyncScriptOnLoad = () => {
    setCallBack("called!");
  };

  function checkFromCountry(e) {
    setIsCountryOther(e.target.value);
    console.log("Country From:", isCountryOther);
  }

  const getCountryCode = async () => {
    const detectCountryCode = await axios.get('https://ipwho.is/')
    setCurrentCountryCode(detectCountryCode.data.country_code);
  }

  function handleConversion() {
    window.gtag("event", "conversion", { send_to: ["AW-10884171449/bPjtCPfj98EDELmN_cUo"]});
    // console.log("Handle Conversion", window.gtag);
  }

  function checkForm(e) {

    if(charCount >= 25 && value && isPossiblePhoneNumber(value)) {
      // do the action in form
      // alert ("fields valid");
      
    } else if (charCount < 25) {
      alert("Message should be Minimum of 25 Characters/Words");
      e.preventDefault();
    } else if(value && isPossiblePhoneNumber(value) == false) {
      alert("Please enter a valid phone number");
      e.preventDefault();
    } else {
      alert("An error occured please try again");
      e.preventDefault();
    }
  }

  const countWords = (e) => {
    const text = e.target.value
    setMessageVal(text);
    const stringText = text.toString();
    if(text == '') {
      setCharCount(0);
    } else {
      setCharCount(stringText.length);
    }
    
  }
  
  useEffect(() => {
    getCountryCode();
  }, [currentCountryCode])

  const onSubmit = useCallback((data: FormData) => {
    setLoading(true);
    setError('');
    fetch(site.siteMetadata.functionEndpoint, {
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then(response => {
        if (response.status === 500) {
          setError(ERROR_MESSAGE);
          setSubmitted(true);
        } else if (response.status === 400) {
          setError('Form Error, please check if provided data is correct');
        } else {
          setSubmitted(true);
        }
      })
      .catch(() => setError(ERROR_MESSAGE))
      .finally(() => {
        setLoading(false);
      });
  }, [site.siteMetadata.functionEndpoint]);

  return (
   <>
      {/* <Helmet>
      <script src="https://www.google.com/recaptcha/api.js"></script>
     </Helmet> */}
    <Wrapper action="https://usebasin.com/f/216294d39461" method="post" accept-charset="UTF-8" id="china-form" onSubmit={checkForm}>
      <input type="hidden" name="_shipkoo"/>
      <HalfBox ref={innerRef}>
        <Input
          placeholder={intl.formatMessage({ id: "c-contact-form-input1-label" })}
          ref={register({ required: 'Company name is required', maxLength: 30 })}
          name="company_name"
          error={getErrors('company_name')}
        />
      </HalfBox>
      <HalfBox>
        <Input
          placeholder={intl.formatMessage({ id: "c-contact-form-input2-label" })}
          name="name"
          ref={register({ required: 'Name is required', maxLength: 80 })}
          error={getErrors('name')}
        />
      </HalfBox>
      <HalfBox>
        <PhoneInput
          placeholder={intl.formatMessage({ id: "c-contact-form-input3-label" })}
          international
          countryCallingCodeEditable={false}
          value={value}
          onChange={setValue}
          defaultCountry={currentCountryCode}
          error={value ? (isPossiblePhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number'}
          name="phone"
          required
        />
      </HalfBox>
      <HalfBox>
        <Input
          placeholder={intl.formatMessage({ id: "c-contact-form-input4-label" })}
          type="email"
          name="email"
          ref={register({ required: 'E-mail is required', pattern: /^\S+@\S+$/i })}
          error={getErrors('email')}
        />
      </HalfBox>
      <BoxSelect>
        <Select name="mode_of_transport" ref={register({ required: 'Mode of Transport is required'})} error={getErrors('mode_of_transport')} required>
          <option hidden value="">Mode of Transport</option>
          <option value="LCL">LCL</option>
          <option value="FCL">FCL</option>
          <option value="Others">Others</option>
        </Select>
      </BoxSelect>
      <BoxSelect>
        <Select className={isCountryOther ? "mb-0" : "" } name="from" ref={register({ required: 'From Country is required'})} error={getErrors('from')} required onChange={checkFromCountry}>
          <option hidden value="">From:</option>
          <option value="China">China</option>
          <option value="Others">Others</option>
        </Select>
      </BoxSelect>
      {isCountryOther == "Others" ?
        <Box style={{marginBottom: "10px", marginTop: "0px"}}>
          <p><b>Please specify the location on your message.</b></p>
        </Box>
        :
        null
      }
      
      <BoxSelect>
        <Select name="to" ref={register({ required: 'To Country is required'})} error={getErrors('to')} required>
          <option hidden value="">To:</option>
          <option value="UK">UK</option>
          <option value="US">US</option>
          <option value="Canada">Canada</option>
          <option value="Europe">Europe</option>
        </Select>
      </BoxSelect>
      <Box>
          <textarea
            placeholder={intl.formatMessage({ id: "c-contact-form-input5-label" })}
            name="message"
            ref={register({ required: 'Please fill the message', maxLength: 500 })}
            error={getErrors('message')}
            onChange={countWords}
            className="textarea-message"
            required
          />
          {/* <p>
          {wordCount}/{limit} words
        </p> */}
        <input style={{display: "none"}} type="hidden"  value="China Air Freight" name="service_name"/>
      </Box>
      {/* <Box>
        <div className="g-recaptcha" data-sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"></div>
      </Box> */}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ButtonContainer>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            {/* <ArrowButton type="submit" disabled={submitted} className="send-form" > */}
            <ArrowButton type="submit" disabled={submitted} className="send-form" className="h-captcha" data-sitekey="7fe715a1-151f-4c63-b497-bd971974df05" data-callback='onSubmit' data-badge="inline">
              {submitted ? 'Message Sent' : loading ? 'Loading...' : 'Send Message'}
            </ArrowButton>
          </div>
          <div className="zh">
            {/* <ArrowButton type="submit" disabled={submitted} className="send-form" > */}
            <ArrowButton type="submit" disabled={submitted} className="send-form" className="h-captcha" data-sitekey="7fe715a1-151f-4c63-b497-bd971974df05" data-callback='onSubmit' data-badge="inline">
              {submitted ? '消息发送' : loading ? 'Loading...' : '发送消息'}
            </ArrowButton>
          </div>
          <div className="kr">
            {/* <ArrowButton type="submit" disabled={submitted} className="send-form" > */}
            <ArrowButton type="submit" disabled={submitted} className="send-form" className="h-captcha" data-sitekey="7fe715a1-151f-4c63-b497-bd971974df05" data-callback='onSubmit' data-badge="inline">
              {submitted ? '보낸 메시지' : loading ? 'Loading...' : '메시지 보내기'}
            </ArrowButton>
          </div>
          <div className="ja">
            {/* <ArrowButton type="submit" disabled={submitted} className="send-form" > */}
            <ArrowButton type="submit" disabled={submitted} className="send-form" className="h-captcha" data-sitekey="7fe715a1-151f-4c63-b497-bd971974df05" data-callback='onSubmit' data-badge="inline">
              {submitted ? 'メッセージを送信しました' : loading ? '読み込んでいます...' : 'メッセージを送る'}
            </ArrowButton>
          </div>
        </div>
      </ButtonContainer>
    </Wrapper>
    </>
  );
};

export default ContactFormChina;
