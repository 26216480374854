import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { scrollToRef } from 'common/scrollToRef';
import { LinkData } from 'models/Links';
import { BaseP, HeaderMedium, MediumTextTransparent } from 'components/typography';
import Layout from 'components/Layout';
import { servicesLinks, servicesLinksChinese, servicesLinksKorean, servicesLinksJapanese } from 'components/Layout/navigation';
import Header from './Header';
import TabNavigator from 'components/TabNavigator';
import Process, { TimelineItem } from './Process';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import WhySourcingWithShipkoo from 'images/why-sourcing-with-shipkoo.png';
import HowWeDo from 'images/how-we-do-img.png';
import WhatIsSoGoodAboutIt from 'images/what-is-so-good-about-it-img.png';
import CTABG from 'images/crowdfunding-fulfillment/cta-bg.png';


const BasePa = styled.h3`
  font-size: 14px;
  font-weight: 300;
  color: #00AF85;
  margin-bottom: 35px;

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;

const Features = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  width: 100%;

  .features-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .feature-subtitle {
    font-size: 30px;
    margin-bottom: 15px;
  }

  h2 {
    margin-bottom:45px;
  }

  .column-one-third {
    width: 33%;
    padding-right: 15px;
    padding-left:15px;
    display: inline-block;
    float: left
  }

  .column-two-third {
    width: 66%;
    display: inline-block;
    float: left;
    padding-right: 15px;
    padding-left:15px;
  }

  .row {
    margin-left:-15px;
    margin-right:-15px;
    position:relative;
  }

  .last {
    padding-right: 0; 
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .features-container .row {
    padding-top:35px;
    padding-bottom:35px;
  }

  .features-image {
    max-width: 250px;
  }

  .features-container p {
    font-size: 18px;
    color: #a4b0ad;
    line-height: 35px;
  }

  @media only screen and (max-width:768px) {
    .column-one-third {
      width: 100%;
      margin-bottom:20px;
    }
    .column-two-third {
      width: 100%;
    }
    .features-container .row { 
      padding-top:15px;
      padding-bottom:15px;
    }

    h2 {
      margin-bottom:20px;
    }

    .feature-subtitle {
      font-size:25px;
    }
  }

`;

const CTA = styled.div`
  position: relative;
  width: 100%;
  .cta-bg {
    position: absolute;
    top: 0;
    left:0;
    object-fit: cover;
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  .cta-container {
    max-width: 1300px;
    padding-left:15px;
    padding-right:15px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-top: 50px;
    padding-bottom: 65px;
    text-align: center;
    color: #fff;
  }

  .cta-container h2 {
    font-size:35px;
    margin-bottom:30px;
  }

  .cta-container a {
    color: #fff;
    margin-top: 30px;
    display: block;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    border: solid 3px #fff;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 18px;
  }

  .cta-container p {
    font-size:18px;
    line-height:35px;
  }

  @media only screen and (max-width:768px) {
    .cta-container h2 {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-top: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-top: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

interface OwnProps {
  about: string;
  header: React.ReactElement;
  description: React.ReactElement;
  description2: React.ReactElement;
  cards: string[];
  timelineItems: TimelineItem[];
  navigationTabs: LinkData[];
  activeTab: string;
  BackgroundImage: React.ElementType;
  cardsTitle: string[];
  IconCardOne: React.ElementType;
  IconCardTwo: React.ElementType;
  IconCardThree: React.ElementType;
}

const Service = ({
  navigationTabs,
  activeTab,
  cards,
  description,
  description2,
  header,
  about,
  numSteps,
  timelineItems,
  BackgroundImage,
  cardsTitle,
  IconCardOne,
  IconCardTwo, 
  IconCardThree,
}: OwnProps) => {
  const ref = useRef(null);

  const handleClick = () => {
    scrollToRef(ref, 300);
  };
  
  const intl = useIntl()
 
  return (
    <Layout>
      <Helmet>
        <title>China Air Freight | Shipkoo</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "china-air-freight-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "china-air-freight-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "china-air-freight-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "china-air-freight-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content="China Air Freight | Shipkoo" />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content="China Air Freight | Shipkoo" />
        <meta name="twitter:description" content="" />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={servicesLinks} activeTab={servicesLinks[4].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={servicesLinksChinese} activeTab={servicesLinksChinese[4].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={servicesLinksKorean} activeTab={servicesLinksKorean[4].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={servicesLinksJapanese} activeTab={servicesLinksJapanese[4].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / <Link to="/services/">{intl.formatMessage({ id: "menu-services" })}</Link> / {intl.formatMessage({ id: "menu-china-air-freight" })}</Breadcrumbs>
      <Header
        BackgroundImage={BackgroundImage}
        cards={cards}
        IconCardOne={IconCardOne}
        IconCardTwo={IconCardTwo}
        IconCardThree={IconCardThree}
        cardsTitle={cardsTitle}
        description={description}
        description2={description2}
        header={header}
        about={about}
        handleClick={handleClick}
      />
      {/* <Process innerRef={ref} numSteps={numSteps} timelineItems={timelineItems} /> */}
      <Features>
        {/* <div className="features-container">
          <BasePa>{intl.formatMessage({ id: "china-air-freight-features" })}</BasePa>
          {/* <HeaderMedium>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-features-title" })}
          </HeaderMedium> */}
          {/* <div className="row">
            <div className="column-one-third">
              <img src={WhySourcingWithShipkoo} className="features-image" alt="Why Sourcing With Shipkoo" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "china-air-freight-feature-first-subtitle" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-first-desc-1" })}
              </p>
              <br />
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-first-desc-2" })}
              </p>
              <br />
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-first-desc-3" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={HowWeDo} className="features-image" alt="How We Do" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "china-air-freight-feature-second-subtitle" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-second-desc-1" })}
              </p>
              <br />
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-second-desc-2" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={WhatIsSoGoodAboutIt} className="features-image" alt="What Is So Good About It" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "china-air-freight-feature-third-subtitle" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-third-desc-1" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={WhatIsSoGoodAboutIt} className="features-image" alt="What Is So Good About It" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "china-air-freight-feature-fourth-subtitle" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-fourth-desc-1" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="row">
            <div className="column-one-third">
              <img src={WhatIsSoGoodAboutIt} className="features-image" alt="What Is So Good About It" />
            </div>
            <div className="column-two-third">
              <h3 className="feature-subtitle">
                {intl.formatMessage({ id: "china-air-freight-feature-fifth-subtitle" })}
              </h3>
              <p>
                {intl.formatMessage({ id: "china-air-freight-feature-fifth-desc-1" })}
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div> */}
      </Features>
      {/* <CTA>
        <img src={CTABG} className="cta-bg" />
        <div className="cta-container">
          <h2>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-title" })}
          </h2>
          <p>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-desc1" })}
          </p>
          <p>
            {intl.formatMessage({ id: "crowdfunding-fulfillment-cta-desc2" })}
          </p>
          <a href="/contact" className="cta-btn">
            {intl.formatMessage({ id: "get-in-touch" })}
          </a>
        </div>
      </CTA> */}
    </Layout>
  );
};

export default Service;
